<template lang="">

    <div class="card">
  
        <div class="card-header border-0">
            <div class="card-title w-100 d-flex justify-content-between">
                <h1>Markalar </h1>
                <a href="#"
                data-bs-toggle="modal"
                id="marka_ekle_buton"
                data-bs-target="#kt_modal_add_brand" class="btn btn-primary">
                <span class="svg-icon svg-icon-1"><i class="fa fa-plus"></i> </span>Yeni Marka</a>
            </div>
        </div>
        <div class="card-body pt-0 pb-5">
            <veri-tablo :datas="brands" :headers="tableHeader" :links="links" :metas="metas">
                <template v-slot:cell-brandName="{ row: brand }">
                    {{ brand.brandName }}
                </template>
                <template v-slot:cell-slogan="{ row: brand }">
                    {{ brand.slogan.slice(0,30) }} ...
                </template>
                
                <template v-slot:cell-color="{ row: brand }">
                    
                    <div class="colorful-div shadow-sm" :style='{"background" : brand.color }'>
                        {{ brand.color }}
                    </div>
                </template>
                <template v-slot:cell-actions="{ row: brand }">
                    <el-dropdown trigger="click">
                        <span class="el-dropdown-link rounded p-3 bg-light">
                            İşlemler<el-icon class="el-icon--right"><arrow-down /></el-icon>
                        </span>
                        <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item>Görüntüle</el-dropdown-item>
                            <el-dropdown-item @click="chooseBrand(brand)">
                               <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_update_brand"  id="marka_update_buton" style="color:var(--el-text-color-regular);"> Güncelle </a>

                            </el-dropdown-item>
                            <el-dropdown-item @click="deleteItem(brand)" >Sil</el-dropdown-item>
                        </el-dropdown-menu>
                        </template>
                    </el-dropdown>

                    <!--end::Menu-->
                </template>
            </veri-tablo>
        </div>
    </div>
    <empty-modal modalId="kt_modal_add_brand" ref="eklemodal__ref" modalTitle="Marka Yarat"> 
        <template v-slot:ModalBody>
            <div class="row">
                <div class="col-md-9"> 
                    <div class="input-group mb-5">
                        <label for="" class="form-label">Marka Adı </label>
                        <input type="text" v-model="brandName" class="form-control w-100" placeholder="Marka Adı" aria-label="Username" aria-describedby="basic-addon1"/>
                    </div>
                </div>
                
                <div class="col-md-3">
                    <label for="" class="form-label">Marka Rengi</label>
                    <input type="color" v-model="color" class="form-control form-control-color" style="width:10rem;" id="exampleColorInput" title="Choose your color">
                </div>
            </div>
            
            <div class="row">
                <div class="col-md-12"> 
                    <div class="input-group mb-1">       
                        <label for="" class="form-label">Marka Slogan</label>   
                    </div>
                </div>
            </div> 
            <div class="row">
                <div class="col-md-12"> 
                    <div class="input-group mb-5">       
                        <input type="text" v-model="slogan" class="form-control" placeholder="Slogan" aria-label="Username" aria-describedby="basic-addon1"/>
                    </div>
                </div>
            </div> 

            <div class="col-md-12">
                <label for="" class="form-label">Marka Açıklama</label>
                <textarea class="form-control" v-model="explanation" data-kt-autosize="true"></textarea>        
            </div>
            <div class="col-md-12">
                <label for="" class="form-label">Marka İçerik </label>
                <textarea class="form-control" v-model="content" data-kt-autosize="true"></textarea>        
            </div>
            
        </template>
        <template v-slot:ModalFooter>
            <a 
            id="marka_ekle_buton"
            @click="save()"
            class="btn btn-primary"> 
                Kaydet
            </a>
            <a @click="eklemodal__ref.close()"
            id="marka_ekle_buton"
            class="btn btn-danger"> 
                Kapat
            </a>
        </template>
    </empty-modal>
    <empty-modal modalId="kt_modal_update_brand" ref="guncellemodal__ref" modalTitle="Marka Güncelle"> 
        <template v-slot:ModalBody>
           
            <div class="row">
                <div class="col-md-9"> 
                    <div class="input-group mb-5">
                        <label for="" class="form-label">Marka Adı </label>
                        <input type="text" v-model="choosenBrand.brandName" class="form-control w-100" placeholder="Marka Adı" aria-label="Username" aria-describedby="basic-addon1"/>
                    </div>
                </div>
                
                <div class="col-md-3">
                    <label for="" class="form-label">Marka Rengi</label>
                    <input type="color" v-model="choosenBrand.color" class="form-control form-control-color" style="width:10rem;" id="exampleColorInput" title="Choose your color">
                </div>
            </div>
            
            <div class="row">
                <div class="col-md-12"> 
                    <div class="input-group mb-1">       
                        <label for=""  class="form-label">Marka Slogan</label>   
                    </div>
                </div>
            </div> 
            <div class="row">
                <div class="col-md-12"> 
                    <div class="input-group mb-5">       
                        <input type="text" v-model="choosenBrand.slogan" class="form-control" placeholder="Slogan" aria-label="Username" aria-describedby="basic-addon1"/>
                    </div>
                </div>
            </div> 


            <div class="col-md-12">
                <label for="" class="form-label">Marka Açıklama</label>
                <textarea class="form-control" v-model="choosenBrand.explanation" data-kt-autosize="true"></textarea>        
            </div>
            <div class="col-md-12">
                <label for="" class="form-label">Marka İçerik </label>
                <textarea class="form-control" v-model="choosenBrand.content" data-kt-autosize="true"></textarea>        
            </div>
            
        </template>
        <template v-slot:ModalFooter>
            <a 
            id="marka_ekle_buton"
            @click="updateBrand"
            class="btn btn-primary"> 
                Kaydet
            </a>
            <a @click="guncellemodal__ref.close()"
            id="marka_ekle_buton"
            class="btn btn-danger"> 
                Kapat
            </a>
        </template>
    </empty-modal>

</template>
<script>

import { ElDropdown } from 'element-plus';
import { ArrowDown } from '@element-plus/icons-vue';

import { useStore } from "vuex";
import { computed, onMounted ,onBeforeMount, ref } from "vue";
import store from '@/store';
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import VeriTablo from '@/components/tablo/VeriTablo.vue'
import EmptyModal from '@/components/modals/forms/EmptyModal.vue';
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { inject } from 'vue';

import  useCruds  from '@/composables/useCrud.js';

export default {
  components: {
    VeriTablo,
    EmptyModal,
  },
    setup() {
        const brandName = ref('');
        const color = ref('#563d7c');
        const slogan = ref('');
        const explanation = ref('');
        const content = ref('');
        const choosenBrand = ref('');

        const guncellemodal__ref = ref();
        const eklemodal__ref = ref();

        const { saveCrud, updateCrud, deleteCrud,loadCrud } = useCruds();


        const tableHeader = [
            {
                name: "Marka Adı",
                key:'brandName'
            },
            {
                name: "Slogan",
                key: "slogan",
            },
            {
                name: "Renk",
                key: "color",
            },
            {
                name: "İşlemler",
                key: "actions",
            },
        ];
        const store = useStore();
        const router = useRouter();

        let brands = computed(() => {
            return store.getters.allBrands;
        });

        let links = computed(() => {
            return store.getters.linkObject;
        });
         let metas = computed(() => {
            return store.getters.metaObject;
        });



        const deleteItem = (item) => {          
            deleteCrud({slug:"/brands/"+item.brandId}).then(function(value){
                if(value){
                    for (let i = 0; i < brands.value.length; i++) {
                        if (brands.value[i] === item) {
                            brands.value.splice(i, 1);
                        }
                    }
                }
            });
        };

        const chooseBrand = (brand) => {
            choosenBrand.value = brand;
        };


        const save = () => {
            var brand = {
                "brandName": brandName.value,
                "slogan": slogan.value,
                "tag": "a",
                "color": color.value,
                "image": null,
                "explanation": explanation.value,
                "content": content.value
            }
            saveCrud({slug:'/brands',data:brand}).then(
                function(value) {
                    brands.value.unshift(value.data);
                    eklemodal__ref.value.close();
                }
            );
        };
        const emitter  = inject('emitter');

        const updateBrand = (event) => {
            updateCrud({slug:"/brands/"+choosenBrand.value.brandId, data:choosenBrand.value}).then(
                function(value) {
                    guncellemodal__ref.value.close();
                }
            );
        }
        onMounted(() => { 
            store.dispatch(Actions.LOAD_BRANDS);
            emitter.on('PageButtonClicked', (value) => {   
                //send the page number as a param to the api
                //etc : value => {page : 1}   
                console.log("Markalar Çalıştı");
                if(router.currentRoute.value.path == "/brands"){
                    store.dispatch(Actions.LOAD_BRANDS,value); 
                }
            });
        });
        
       
        return {
            tableHeader,
            brands,
            deleteItem,
            brandName,
            color,
            slogan,
            explanation,
            content,
            save,
            links,
            metas,
            eklemodal__ref,
            chooseBrand,
            choosenBrand,
            updateBrand,
            guncellemodal__ref,
        }
    }
}


</script>
<style lang="css">
   .colorful-div{
        width:100px;
        height:24px;
        border-radius:8px;
        padding:5px;
        background:#fff;
        font-family: 'Comfortaa', cursive;
        cursor:pointer;
        color:#fff;
        margin:auto;
   }
   
   .badge{
       width:24px;
       height:24px;
       display:flex;
       justify-content:center;
       align-items:center;
       align-content:center;
   }
</style>